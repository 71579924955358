<template>
  <div class="mainStuff">
    <h2>Jooooooooooooo Digga </h2><br>
    <h3>also ich mach das churz: mir hend denkt mir laded dich z 3. ih 3 Täg uf Amsterdam zur fiir vom dim 30. Geburtstag. Natürlich am Datum dinere Wahl, sowiit möglich</h3>

    <h2> Alles gueti und nur s beste und all de üblich plunder wommer dir wirklich vo härze wünsched </h2>
    <div>
      
      <span class="emoji">😄</span>
      <span class="emoji">💜</span>
      <span class="emoji">💩</span>
    </div>
    <div>
      <img src="/420.png">
    </div>
    <div>
      <img src="/smisBackground.jpg" alt="">
    </div>

  </div>

</template>

<script>

export default {
  name: 'App',
}
</script>

<style>

body{
  background-color: black;
  color: white;
}


.emoji {
  font-size: 2em;
	display: inline-block;
	animation: 
		color .66s linear infinite,
		rotate 1s ease-in-out infinite;

}

@keyframes color {
	50% { filter: hue-rotate(360deg); }
}

@keyframes rotate {
	0%, 100% { transform: rotate(20deg); }
	25% { transform: scale(1.1); }
	50% { transform: rotate(-20deg); }
	75% { transform: scale(.9); }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}
.mainStuff{
  height: 100%;
  min-height: 800px;
  display: block;
}
</style>
